<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <b-img
        fluid
        :src="logo"
        alt="Error page"
        style="max-width: 100px"
      /><!--
      <h2 class="brand-text text-primary ml-1">
        {{ name }}
      </h2>
    -->
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          Pagina não encontrada 🕵🏻‍♀️
        </h2>
        <p class="mb-2">
          {{ $t('OPS404') }}
        </p>

        <b-button
          variant="primary"
          class="mb-2 btn-sm-block"
          :to="{path:'/'}"
        >
          Voltar para o inicio
        </b-button>
        <br>
        <!-- image -->
        <b-img
          fluid
          :src="logo"
          alt="Error page"
        />
      </div>
    </div>
  </div>
<!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BLink, BButton, BImg } from 'bootstrap-vue'
import store from '@/store/index'

export default {
  components: {
    BLink,
    BButton,
    BImg,
  },
  data() {
    return {
      logo: '',
      isBusy: false,
      downImg: require('@/assets/init/logo.svg'),
    }
  },
  methods: {
    getPersonalizacao() {
      const logoLocalStorage = localStorage.getItem('logo');
      const bannerLocalStorage = localStorage.getItem('banner');
      const titleLocalStorage = localStorage.getItem('title');
      const descriptionLocalStorage = localStorage.getItem('description');
      let requestGetOn = false

      if (typeof logoLocalStorage === 'undefined' || logoLocalStorage === null || logoLocalStorage.includes('undefined') || logoLocalStorage.includes('null')) {
        this.logo = require('@/assets/default/logo.png')
        requestGetOn = true
      } else {
        this.logo = logoLocalStorage
      }

      if (typeof bannerLocalStorage === 'undefined' || bannerLocalStorage === null || bannerLocalStorage.includes('undefined') || bannerLocalStorage.includes('null')) {
        this.banner = require('@/assets/default/banner.png')
        requestGetOn = true
      } else {
        this.banner = bannerLocalStorage
      }

      if (typeof titleLocalStorage === 'undefined' || titleLocalStorage === null || titleLocalStorage.includes('undefined') || titleLocalStorage.includes('null')) {
        this.title = 'Plataforma OP3'
        requestGetOn = true
      } else {
        this.title = titleLocalStorage
      }

      if (typeof descriptionLocalStorage === 'undefined' || descriptionLocalStorage === null || descriptionLocalStorage.includes('undefined') || descriptionLocalStorage.includes('null')) {
        this.description = 'uma plataforma completa para transmissões ao vivo, gravação, gestão e controle de cursos'
        requestGetOn = true
      } else {
        this.description = descriptionLocalStorage
      }

      if(requestGetOn == true){
        axios.get(`${process.env.VUE_APP_API}/personalizar`)
          .then(response => {
            if(response.data.response.logo != null && response.data.response.logo != 'undefined' && !response.data.response.logo.includes('undefined') && !response.data.response.logo.includes('null')){
              this.logo = `${process.env.VUE_APP_API_BASE}/storage/${response.data.response.logo}`
              localStorage.setItem('logo', this.logo);

            }
            if(response.data.response.banner != null && response.data.response.banner != 'undefined' && !response.data.response.banner.includes('undefined') && !response.data.response.banner.includes('null')){
              this.banner = `${process.env.VUE_APP_API_BASE}/storage/${response.data.response.banner}`
              localStorage.setItem('banner', this.banner);

            }
            if(response.data.response.name != null && response.data.response.name != 'undefined' && !response.data.response.name.includes('undefined') && !response.data.response.name.includes('null')){
              this.title = `${response.data.response.name}`
              localStorage.setItem('title', this.title);

            }
            if(response.data.response.description != null && response.data.response.description != 'undefined' && !response.data.response.description.includes('undefined') && !response.data.response.description.includes('null')){
              this.description = `${response.data.response.description}`
              localStorage.setItem('description', this.description);

            }
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
  },
  beforeMount() {
    this.getPersonalizacao()
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/init/logo.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
